import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import RumcurrentFeed from "../components/rumcurrentfeed"
import RumpasteventsFeed from "../components/rumpasteventsfeed"

const RumHub = () => (

  <Layout>
    <SEO title="Rum Collection" />
  
    <PostHero
        title="Rum Collection"
        description="The sugar cane spirit"
        image={require('../images/pexels-frank-meriño-3612023.jpg')}
    />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="hub-cards-section">
                <RumcurrentFeed />
            </div>

            <div className="hub-section-title center">
                <h2>Recent Rum Events</h2>
            </div>
            <div className="hub-cards-section">
                <RumpasteventsFeed />
            </div>

         </div>

      </div>

  </Layout>
)

export default RumHub